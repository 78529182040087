exports.onInitialClientRender = () => {

  require('typeface-lato')
  require('typeface-open-sans')

  let width = window.screen.width
  document.body.style.backgroundImage = "url('//res.cloudinary.com/mazedonia/image/upload/c_scale,f_auto,w_" + width + "/v1555958572/terminala.es/background')"

  let mainImg = getMainImg()
  document.getElementById("main").appendChild(mainImg)

  window.onresize = function(event) {
    let mainImg = getMainImg()
    document.getElementById("main").replaceChild(mainImg, document.getElementById("main").childNodes[0])
  }

  function getMainImg(){
    let width = window.screen.width
    let img = document.createElement("img")
    if (width > 768) {
      img.setAttribute("src","//res.cloudinary.com/mazedonia/image/upload/c_lfill,h_250,w_600/v1555972991/terminala.es/main")
    } else {
      img.setAttribute("src","//res.cloudinary.com/mazedonia/image/upload/c_lfill,h_250,w_728/v1555972991/terminala.es/main")
    }
    return img
  }

}